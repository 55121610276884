/*
 * @Author: Robin.lau
 * @Email: 329080237@qq.com
 * @Wechat: 15618110227
 */
/*
    前端字典表维护，用于表格等地方展示
*/

export const dossierList = [
  {name: "Reading and Writing Module1", value: "A"},
  {name: "Reading and Writing Module2-Hard", value: "C"},
  {name: "Math Module 1", value: "D"},
  {name: "Math Module 2- Hard", value: "F"}
]