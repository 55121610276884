/*
 * @Author: Robin.lau
 * @Email: 329080237@qq.com
 * @Wechat: 15618110227
 */
//类型管理
import * as optionsList from '@/utils/optionsList';

const Filter = {
  // 补零
  fillZero: num => {
    return num < 10 ? '0' + num : num;
  },
  parseColor: status => {
    let num = Number(status),
      color = "";
    if (num === 0 || num === 1) {
      color = "color-danger";
    }
    if (num === 2) {
      color = "color-success";
    }
    return color;
  },
};
export default Filter;


/**
 * 根据字典表返回中文
 * @param {String} string
 * @param {label} string
 */
export function getTcodeName(str = '', label) {
  //统一将值转换为字符串
  str = str.toString()
  const list = optionsList[label].filter(item => {
    return item.value === str || item.label === str;
  });
  let typeName = '';
  if (list.length > 0) {
    typeName = list[0].name || list[0].label;
  }
  return typeName;
}


/**
 * 根据枚举值返回中文
 * @param {String} string
 * @param {tocdeList} Array
 */
export function getTcodeListName(str = '', tocdeList) {
  const list = tocdeList.filter(item => {
    return item.value === str.toString();
  });
  let typeName = '';
  if (list.length > 0) {
    typeName = list[0].name;
  }
  return typeName;
}


//日期转换成 星期几？
export function parseTime(time, cFormat) {
  const val = new Date(time).getDay()
  return ['周日', '周一', '周二', '周三', '周四', '周五', '周六'][val]
}

//离当前时间过了多久
export function timesFun(timesData) {

  //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
  let dateBegin = new Date(timesData.replace(/-/g, "/")); //将-转化为/，使用new Date
  dateBegin = new Date(timesData.replace(/T/g, " ")); //将日期中 T 转化为 空格 (Django返回日期中带 T，须经这步处理)

  let dateEnd = new Date(); //获取当前时间
  let dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
  let dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
  let leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
  //计算相差分钟数
  let leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
  //计算相差秒数
  let leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
  let seconds = Math.round(leave3 / 1000);
  let timesString = '';

  if (dayDiff > 0) {
    timesString = dayDiff + '天之前';
  } else if (dayDiff == 0 && hours > 0) {
    timesString = hours + '小时之前';
  } else if (dayDiff == 0 && hours == 0 && minutes >= 1) {
    timesString = minutes + '分钟之前';
  } else if (minutes < 1) {
    timesString = '刚刚';
  }
  return timesString
}

//日期格式化，默认返回 年-月-日 时:分:秒
//可自定义返回任意格式字符串，例如，只返回 时：分：秒，则传入 "hh:mm:ss"
export function formatTime(timestamp, format = "YY-MM-DD hh:mm:ss") {
  if (!timestamp) {
    return ""
  }
  let time = new Date(timestamp);
  let year = time.getFullYear();
  const month = (time.getMonth() + 1).toString().padStart(2, '0');
  const date = (time.getDate()).toString().padStart(2, '0');
  const hours = (time.getHours()).toString().padStart(2, '0');
  const minute = (time.getMinutes()).toString().padStart(2, '0');
  const second = (time.getSeconds()).toString().padStart(2, '0');

  let fdate = format.replace("YY", year).replace("MM", month).replace("DD", date).replace("hh", hours).replace("mm",
    minute).replace("ss", second)
  return fdate
}

// 正则过滤MD标签字符串
export function filterMD(str) {
  let pattern = new RegExp("[#*~\\[\\]>`!|=-¶()\\/:：'%,+\"<-]")
  var rs = "";
  for (var i = 0; i < str.length; i++) {
    rs = rs + str.substr(i, 1).replace(pattern, '').replace("，，", "");
  }
  return rs;
}

// 正则过滤HTML所有标签,提取纯文本
export function filterHTML(str) {
  if (str) {
    return str.replace(/<.*?>/g, "")
  }
}

//截取字符串长度带MD格式过滤
export function cutStr(value, len, md = true) {
  let filter_str = value
  if (md) {
    filter_str = filterHTML(value)
  }
  if (filter_str && filter_str.length > len) {
    filter_str = filter_str.substring(0, len) + '...'
  }
  return filter_str
}

//手机号码隐藏中间四位
export function phoneNumber(str) {
  if (str) {
    let number = /^(.{3}).*(.{4}$)/
    return str.replace(number, '$1****$2')
  } else {
    return str
  }
}

//截取文件名省略显示
export function cutFilename(value, len) {
  if (value && value.length > len) {
    let ext = value.split(".").pop()
    let filename = value.replace(ext, "")
    filename = filename.substring(0, len) + '...'
    return filename + ext
  } else {
    return value
  }
}

// 文件尺寸单位转换
export function formatFileSize(fileSize) {
  if (fileSize < 1024) {
    return fileSize + ' KiB';
  } else if (fileSize < (1024 * 1024)) {
    var temp = fileSize / 1024;
    temp = temp.toFixed(0);
    return temp + ' MiB';
  } else if (fileSize < (1024 * 1024 * 1024)) {
    var temp = fileSize / (1024 * 1024);
    temp = temp.toFixed(2);
    return temp + ' GiB';
  } else {
    var temp = fileSize / (1024 * 1024 * 1024);
    temp = temp.toFixed(2);
    return temp + ' TiB';
  }
}

//10位时间戳转换成字符串
export function formatTimeUnix(date, fmt = 'yyyy-MM-dd hh:mm:ss') {
  let hdate = date * 1000 //注意：date应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (hdate.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': hdate.getMonth() + 1,
    'd+': hdate.getDate(),
    'h+': hdate.getHours(),
    'm+': hdate.getMinutes(),
    's+': hdate.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
    }
  }
  return fmt
}


//历史时间
export function historyTime(time) {
  let ptime = new Date(time).getTime()

  const twentyFourHours = 24 * 60 * 60 * 1000;
  const fortyEightHours = 24 * 60 * 60 * 1000 * 2;
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const today = `${year}-${month}-${day}`;
  const todayTime = new Date(today).getTime();
  const yesterdayTime = new Date(todayTime - twentyFourHours).getTime();
  const lastYesterdayTime = new Date(todayTime - fortyEightHours).getTime();

  if (ptime >= todayTime) {
    return '今天'
  } else if (ptime < todayTime && yesterdayTime <= ptime) {
    return '昨天'
  } else if (ptime < yesterdayTime && lastYesterdayTime <= ptime) {
    return '前天'
  } else {
    return "三天之前";
  }
}


// 千分位逗号分隔
export function numberToCurrencyNo(value) {

  if (!value) return 0
  // 获取整数部分
  const intPart = Math.trunc(value)
  // 整数部分处理，增加,
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // 预定义小数部分
  let floatPart = ''
  // 将数值截取为小数部分和整数部分
  const valueArray = value.toString().split('.')
  if (valueArray.length === 2) { // 有小数部分
    floatPart = valueArray[1].toString() // 取得小数部分
    return intPartFormat + '.' + floatPart
  }
  return intPartFormat + floatPart
}


/*
 * 获取本周，或下一周的起始日期
 * @type为字符串类型，有两种选择，"s"代表开始,"e"代表结束，
 * @dates为数字类型，不传或0代表本周，-1代表上周，1代表下周
 * getMonday("s",1)  //得到下周一的yyyy-mm-dd格式日期
 * getMonday("e",1)  //得到下周日的yyyy-mm-dd格式日期
 */
export function getMonday(type, dates) {
  var now = new Date();
  var nowTime = now.getTime();
  var day = now.getDay();
  var longTime = 24 * 60 * 60 * 1000;
  var n = longTime * 7 * (dates || 0);
  if (type == "s") {
    var dd = nowTime - (day - 1) * longTime + n;
  };
  if (type == "e") {
    var dd = nowTime + (7 - day) * longTime + n;
  };
  dd = new Date(dd);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1;
  var d = dd.getDate();
  var day = y + "-" + m + "-" + d;
  return day;
}


export function stayHour(time, isSencond=false) {
  // isSencond = false（默认值）时，  当前时间 - time（时间格式） 返回格式化数据
  // isSencond = true 时，time=秒数，将秒数格式化数据

  const d = new Date(time)
  const now = Date.now()
  let sencond = (now - d) / 1000

  if (isSencond==true) { sencond = time }

  let result = ""
  //3600秒等于60分钟等于1小时
  if (sencond > 3600 * 24) { //表示大于一天
    let day = sencond / (3600 * 24);
    sencond = sencond % (3600 * 24); //求天数整除外剩余的秒数
    let hour = sencond / 3600; //1小时==60分钟==3600秒，所以剩余的秒数÷3600= 得到几个小时数
    result = parseInt(day) + "天" + parseInt(hour) + "时";
  } else if (sencond > 3600) { //表示大于一个小时
    let hour = sencond / 3600;
    let minute = sencond % 3600 / 60; //求小时数整除外剩余的秒数， 秒数÷60秒 = 得到几分钟
    result = parseInt(hour) + "时" + parseInt(minute) + "分";
  } else if (sencond > 60) { //表示大于一分钟
    let minute = sencond / 60;
    let sec = sencond % 60; //求分钟数整除外剩余的秒数
    result = parseInt(minute) + "分" + parseInt(sec) + "秒";
  } else {
    result = "" + parseInt(sencond) + "秒";
  }
  return result
}

// 历史停留时间
export function historyStayHour(time) {
  // time : 秒

  let sencond = time
  let result = ""
  //3600秒等于60分钟等于1小时
  if (sencond > 3600 * 24) { //表示大于一天
    let day = sencond / (3600 * 24);
    sencond = sencond % (3600 * 24); //求天数整除外剩余的秒数
    let hour = sencond / 3600; //1小时==60分钟==3600秒，所以剩余的秒数÷3600= 得到几个小时数
    result = parseInt(day) + "天" + parseInt(hour) + "时";
  } else if (sencond > 3600) { //表示大于一个小时
    let hour = sencond / 3600;
    let minute = sencond % 3600 / 60; //求小时数整除外剩余的秒数， 秒数÷60秒 = 得到几分钟
    result = parseInt(hour) + "时" + parseInt(minute) + "分";
  } else if (sencond > 60) { //表示大于一分钟
    let minute = sencond / 60;
    let sec = sencond % 60; //求分钟数整除外剩余的秒数
    result = parseInt(minute) + "分" + parseInt(sec) + "秒";
  } else if (!sencond || sencond <= 0) {
    result = "";
  } else {
    result = "" + parseInt(sencond) + "秒";
  }
  return result
}

export function dateStringToTimestamp(str) {
  let date = new Date(str);
  let timestamp = Math.floor(date.getTime() / 1000);
  return timestamp
}

export function timeStampToString(timeStamp) {
  let date = new Date(timeStamp * 1000)
  let dateString = date.toISOString()
  return dateString
}

export function simplifyBlanks(text) {
  // 从文本中移除<span class="sr-only">blank</span>，保留下划线。
  if (text) {
    return text.replace('<span class="sr-only">blank</span>', "")
  }
}

export function getFirstFourWords(str) {  
  // 使用正则表达式匹配前四个单词，每个单词后面可能跟着零个或多个空格  
  // \b 表示单词边界，\s* 表示零个或多个空格  
  // (?:[^\s]+\s*){1,4} 表示匹配1到4个单词，每个单词后面可能跟着空格  
  // 最后的 \s* 是为了匹配最后一个单词后面的空格（如果有的话）  
  // 注意：这个正则表达式假设单词之间只用空格分隔，并且不考虑标点符号等  
  const match = str.match(/^\s*(?:[^\s]+\s*){1,4}/);  
  // 如果找到了匹配项，则返回匹配项（不包括尾随的空格），否则返回空字符串  
  return match ? match[0].trim() : '';  
}

export function generateRandomPhoneNumber() {  
  const prefixes = ['130', '131', '132', '133', '134', '135', '136', '137', '138', '139',  
                    '145', '147', '150', '151', '152', '153', '155', '156', '157', '158', '159',  
                    '176', '177', '178', '180', '181', '182', '183', '184', '185', '186', '187', '188', '189'
                  ];  
  const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];  
  let remaining = '';  
  for (let i = 0; i < 8; i++) {  
      remaining += Math.floor(Math.random() * 10);  
  }  
  return prefix + remaining;  
}

export function generateRandomString(length, type) {  
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';  
  if (type == "password") {
    characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&';  
  }
  let result = '';  
  const charactersLength = characters.length;  
  for (let i = 0; i < length; i++) {  
      result += characters.charAt(Math.floor(Math.random() * charactersLength));  
  }  
  return result;  
}

export function generateRandomEmail() {  
  // 定义一些可能的域名和顶级域名  
  const domains = ['gmail.com', 'outlook.com', 'yahoo.com', 'example.com'];  
  let username = '';  
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';  
  const charactersLength = characters.length;  
  for (let i = 0; i < Math.floor(Math.random() * 6) + 5; i++) {  
      username += characters.charAt(Math.floor(Math.random() * charactersLength));  
  }  
  const domain = domains[Math.floor(Math.random() * domains.length)];  
  return `${username}@${domain}`;  
}  