// api总入口
import question from './question'
import common from './common'

const api = {
  question,
  common
}

export default api
