import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/style/index.scss' // global css

// import '@/permission' // 路由控制拦截

import md5 from 'js-md5'
Vue.prototype.$md5 = md5

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 导入全局函数
import base from '@/utils/base'
Vue.use(base)

import VueFullscreen from "vue-fullscreen"
Vue.use(VueFullscreen)

//模态框拖拽组件
import '@/directives/dialogDirect'

// import globalMixin from '@/mixins/killCopy'
// Vue.mixin(globalMixin)

//挂载全局filter
import * as filters from '@/utils/filter'
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
