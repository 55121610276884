import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const userRoutes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login')
  },
  // {
  //   path: '/answer/tips',
  //   name: 'answer-tips',
  //   component: () => import(/* webpackChunkName: "answer-tips" */ '../views/answer/PracticeTips')
  // },
  // {
  //   path: '/answer/view',
  //   name: 'answer-view',
  //   component: () => import(/* webpackChunkName: "answer-view" */ '../views/answer/View')
  // },
  {
    path: '/answer',
    name: 'answer',
    component: () => import(/* webpackChunkName: "answer-index" */ '../views/answer')
  },
  {
    path: '/bankList',
    name: 'bankList',
    component: () => import(/* webpackChunkName: "bankList" */ '../views/bankList')
  },
]

const routes = [
  ...userRoutes,
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
