import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10 * 60 * 1000 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    console.log(store.getters.adminToken)
    if (store.getters.adminToken) {
      config.headers['token'] = store.getters.adminToken
    }
    // config.headers['token'] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjbGllbnRfbG9naW5fdXNlcl9rZXkiOiJiMWUzNDVmNDBhNzM0YTVhOTAxM2U4NGNmYmYzYzYyYiJ9.NPMt3UcR5BmybOzFaQ-IkHfHxsjQK6QzUJUymhTbEvo"
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(

  response => {
    const res = response.data
    if (res.code == 403 || res.code == 401) {
      // 未登录或无权限时
      router.push("/")
    }
    return res
  },

  // 请求失败
  error => {
    //console.log('err' + error) // for debug
    console.log(error)
    switch (error.response.status) {
      case 400: error.message = '请求错误(400)' ; break;
      case 401: error.message = '未授权，请重新登录(401)'; break;
      case 403: error.message = '拒绝访问(403)'; break;
      case 404: error.message = '请求出错(404)'; break;
      case 408: error.message = '请求超时(408)'; break;
      case 500: error.message = '服务器错误(500)'; break;
      case 501: error.message = '服务未实现(501)'; break;
      case 502: error.message = '网络错误(502)'; break;
      case 503: error.message = '服务不可用(503)'; break;
      case 504: error.message = '网络超时(504)'; break;
      case 505: error.message = 'HTTP版本不受支持(505)'; break;
      default: error.message = `连接出错(${error.response.status})!`;
    }
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }

)

export default service
