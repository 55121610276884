import request from '@/utils/request'

const query = {

 // 题库列表
 getQuestionBankList(data) {
    return request({
      url: `/getQuestionBankList`,
      method: 'post',
      data
    })
  },

  // 卷宗列表
  getQuestionDossierList(data) {
    return request({
      url: `/getQuestionDossierList`,
      method: 'post',
      data
    })
  },

  // 题目详情
  getQuestionInfoDetail(data) {
    return request({
      url: `/getQuestionInfoDetail/${data}`,
      method: 'get'
    })
  },

  // 题目列表
  getQuestionInfos(data) {
    return request({
      url: `/getQuestionInfos?${data}`,
      method: 'get',
    })
  },

}
export default query
