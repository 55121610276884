//自定义细粒度权限控制

exports.install = function (Vue, options) {
  Vue.prototype.XPermission = function (roles){
    let permissions = ""
    if (localStorage.getItem("sat_manage_my_auth")) {
      permissions = JSON.parse(localStorage.getItem("sat_manage_my_auth")) || ""
      let hasPermissions = permissions.userPermissions.filter(item => roles.includes(item.explain))
      return hasPermissions.length ? true : false
    }
    return false
  }

}
