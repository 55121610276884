import request from '@/utils/request'

const member = {

 // 登录
  login(data) {
    return request({
      url: `/login`,
      method: 'post',
      data
    })
  },

}
export default member
